import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('idle'); // idle, sending, success, error
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (status === 'success') {
      clearFields();
    }
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && email.includes("@")) {
      setStatus('sending');
      fetch('https://backend.ripplee.net/subscribe', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: email })
      })
      .then(response => response.text())
      .then(data => {
          // 使用setTimeout来延迟处理响应
          setTimeout(() => {
              setMessage(data);
              setStatus('success');
          }, 1000); // 延迟1秒
      })
      .catch(error => {
          console.error('Error subscribing email:', error);
          setTimeout(() => {
              setMessage('订阅失败');
              setStatus('error');
          }, 1000); // 延迟1秒
      });
    } else {
      setTimeout(() => {
          setMessage('Please enter a valid email address.');
          setStatus('error');
      }, 1000); // 这里也可以设置延迟，保持一致性
    }
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={5}>
              <h3>订阅我们的最新消息！<br></br> 不错过我们任何一次更新</h3>
              {status === 'sending' && <Alert variant="warning">请求中...</Alert>}
              {status === 'error' && <Alert variant="danger">{message}</Alert>}
              {status === 'success' && <Alert variant="success">{message}</Alert>}
            </Col>
            <Col md={6} xl={7}>
              <form onSubmit={handleSubmit}>
                <div className="new-email-bx">
                  <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="邮箱地址" />
                  <button type="submit">订阅</button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </Col>
  )
}
